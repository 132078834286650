import { left, right, type Either, type NodeServiceClient } from "~/lib/deps";
import { ApiClientError, type ApiError } from "./apiError";

export class BannerService {
  readonly #client: NodeServiceClient;
  readonly #tenant: string;

  constructor(c: NodeServiceClient, t: string) {
    this.#client = c;
    this.#tenant = t;
  }

  async getBanner(showIn: string): Promise<Either<ApiError, URL>> {
    const resultOrError = await this.#client.query(
      [["properties.banner-image:show-in", "==", showIn]],
      1,
      1
    );

    if (resultOrError.isLeft()) {
      return left(new ApiClientError());
    }

    const banners = resultOrError.value.nodes;

    if (banners.length === 0) {
      return left(
        new ApiClientError(`Banner para mostrar em ${showIn.toUpperCase()} não necontrado.`)
      );
    }

    return right(
      new URL(this.#client.getNodeUrl(banners[0].uuid).concat(`?x-tenant=${this.#tenant}`))
    );
  }
}

export async function exportImageBlobToUrl(
  client: NodeServiceClient,
  uuid: string
): Promise<Either<ApiClientError, URL>> {
  const bannerBlobOrError = await client.export(uuid);

  if (bannerBlobOrError.isLeft()) {
    console.error(bannerBlobOrError.value);
    return left(new ApiClientError(bannerBlobOrError.value));
  }

  return right(new URL(URL.createObjectURL(bannerBlobOrError.value)));
}
