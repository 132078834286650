<template>
  <div class="w-full relative flex items-center justify-center py-4">
    <div class="flex flex-col items-center">
      <Logo size="sm" />
      <p class="text-sm font-normal text-neutral-500">All rights reserved to Raviish Studio</p>
    </div>
    <a
      href="https://www.instagram.com/raviish.studio/"
      class="absolute right-10 flex items-center justify-center w-6 h-6 p-1 bg-blury-wood rounded-2xl text-white"
    >
      <Icon name="mdi:instagram" size="24" color="#333333" />
    </a>
  </div>
</template>
